
import { computed, defineComponent, ref } from "vue";
import router from "@/router/Router";
import { useProfileModule } from "@/store/modules/ProfileModule";
import ProfileNavigationMenu from "@/app/private/pages/profile/components/ProfileNavigationMenu.vue";

export default defineComponent({
  name: "TheNavigationMenuProfile",
  components: { ProfileNavigationMenu },
  emits: ["route-change"],
  setup(props, context) {
    const profileCtx = useProfileModule();
    const redirect = (routeName: string) => {
      context.emit("route-change");
      router.push({ name: routeName });
    };
    let isNavHovered = ref(false);

    return {
      redirect,
      user: computed(() => {
        return profileCtx.getters.getUser;
      }),
      avatarHref: computed(() => {
        return profileCtx.getters.getAvatarHref;
      }),
      ProfileNavigationMenu,
      isNavHovered,
    };
  },
});
