import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44c4d434"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_navigation_menu = _resolveComponent("the-navigation-menu")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_the_navigation_menu),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createVNode(_component_the_footer)
    ])
  ], 64))
}