<template>
  <div class="nav-menu-contacts">
    <span class="support">Support:</span>
    <base-icon
      class="icon"
      icon-src="static/icons/shared/phone.svg"
      icon-color="additional-medium"
    ></base-icon>
    <a class="number" href="tel:480-556-5235">(480) 556-5235</a>
  </div>
</template>

<script>
import BaseIcon from "@/app/core/components/BaseIcon";
export default {
  name: "TheNavigationMenuContacts",
  components: { BaseIcon },
};
</script>

<style scoped lang="scss">
.nav-menu-contacts {
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 25px;
  height: 30px;

  .support {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-additional);
  }

  .icon {
    height: 11px;
    width: 11px;
  }

  .number {
    color: var(--text-secondary);
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-decoration: underline;
  }
}
</style>
