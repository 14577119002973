import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b98f6896"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "active"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_navigation_menu = _resolveComponent("profile-navigation-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "info",
    onMouseenter: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isNavHovered = true), ["prevent"])),
    onMouseleave: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.isNavHovered = false), ["prevent"]))
  }, [
    _createElementVNode("img", {
      src: _ctx.avatarHref,
      alt: "avatar"
    }, null, 8, _hoisted_1),
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.user.username), 1))
      : _createCommentVNode("", true),
    (_ctx.$route.matched.some(({ name }) => name === 'ProfileLayout'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: "profile-nav-menu",
      onMouseenter: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.isNavHovered = true), ["prevent"]))
    }, [
      _createVNode(_component_profile_navigation_menu, {
        onCloseProfileNav: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavHovered = false))
      })
    ], 544), [
      [_vShow, _ctx.isNavHovered]
    ])
  ], 32))
}